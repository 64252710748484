.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-color: #fff;
}

.content {
  flex: 1;
  padding: 0;
  margin: 0;
}
