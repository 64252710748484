/* index.css or global styles */

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* prevents horizontal scroll site-wide */
  font-family: Arial, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
