.navbar {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 40px;
  position: relative; 
  z-index: 2000;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  position: relative;
}

.navbar-logo-img {
  height: 60px;
  margin-right: 10px;
}

.navbar-logo-text {
  font-weight: 700;
  font-size: 2rem;
  position: relative;
}

/* Underline effect for entire logo on hover */
.navbar-logo::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 0;
  background-color: black;
  transform-origin: top;
  transition: height 0.2s ease-in-out;
}

.navbar-logo:hover::after {
  height: 4px;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-link {
  position: relative;
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  padding-bottom: 8px;
  transition: color 0.2s ease-in-out;
}

.navbar-link::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 0;
  background-color: black;
  transform-origin: top;
  transition: height 0.2s ease-in-out;
}

.navbar-link:hover {
  color: #000;
}

.navbar-link:hover::after {
  height: 4px;
}

.navbar-icon-link {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-menu {
  display: flex;
}

/* Hides hamburger on desktop */
.mobile-menu-icon {
  display: none;
}

/* Mobile menu hidden by default */
.mobile-menu {
  display: none;
}

/* MOBILE STYLES */
@media (max-width: 768px) {
  /* Fix navbar at top on mobile */
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 20px;
    width: 100%;
    z-index: 2000;
  }

  .desktop-menu {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
    font-size: 1.8rem;
    color: #333;
    cursor: pointer;
    z-index: 2100;
  }

  /* Fullscreen overlay for mobile menu */
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2050;

    /* Fade transition */
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  /* When open, fade in */
  .mobile-menu.open {
    opacity: 1;
    pointer-events: auto;
  }

  .mobile-menu .navbar-link {
    margin: 20px 0;
    font-size: 1.5rem;
  }
}
