.section-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 400px;
    padding: 60px 20px;
    box-sizing: border-box;
    transition: opacity 1s ease, transform 1s ease;
    opacity: 1;
    transform: translateX(0);
  }
  
  .section-container.reverse {
    flex-direction: row-reverse;
  }
  
  .section-container.hidden {
    opacity: 0;
    transform: translateX(50px);
  }
  
  .section-container.reverse.hidden {
    transform: translateX(-50px);
  }
  
  .section-container.fade-in {
    opacity: 1;
    transform: translateX(0);
  }
  
  .section-image {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .section-image img {
    width: 80%;
    max-width: 600px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  }
  
  .section-content {
    flex: 1 1 50%;
    max-width: 600px;
    padding: 10px;
    text-align: left;
  }
  
  .section-content h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .section-content p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .section-container {
      flex-direction: column;
      text-align: center;
    }
    .section-image, .section-content {
      flex: none;
      width: 100%;
    }
    .section-content {
      padding: 20px 10px;
    }
  }
  