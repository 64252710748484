.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

/* Hero Banner */
.hero-banner {
  width: 100%;
  height: 100vh; /* Keep full-screen on desktop */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ddd;
  padding: 0 50px;
  box-sizing: border-box;
  animation: fadeIn 3s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Left side text */
.hero-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 50px;
}

/* Large desktop defaults */
.hero-title {
  font-size: 9rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #111;
  text-transform: uppercase;
}

.hero-subtitle {
  font-size: 3.5rem;
  margin: 10px 0;
  color: #222;
  text-transform: uppercase;
}

/* Photo container with right margin */
.hero-photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;
}
.navbar-logo-text {
  white-space: nowrap;
}

.hero-profile-pic {
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid #063446;
  transition: box-shadow 0.7s ease;
}

@media (hover: hover) {
  .hero-profile-pic:hover {
    box-shadow: 0 0 15px 5px #063446;
  }
}

/* Responsive fonts & layout for smaller screens */
@media (max-width: 1200px) {
  .hero-title {
    font-size: 7rem;
  }
  .hero-subtitle {
    font-size: 2.5rem;
  }
}

@media (max-width: 992px) {
  .hero-title {
    font-size: 6rem;
  }
  .hero-subtitle {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  .hero-banner {
    height: auto;
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  .hero-left {
    margin-right: 0;
    align-items: center;
  }
  .hero-title {
    font-size: 5rem;
  }
  .hero-subtitle {
    font-size: 2rem;
  }
  .hero-photo-container {
    margin-top: 20px;
    margin-right: 0;
  }
  .hero-profile-pic {
    width: 250px;
    height: 250px;
  }
}

/* "More About Me!" Subheading */
.section-intro {
  text-align: center;
  font-size: 2rem;
  margin: 40px 0 20px;
  color: #333;
}

/* Tech Icons Row */
.tech-icons-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.tech-icon {
  position: relative;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.tech-icon:hover {
  transform: scale(1.1) translateY(-3px);
}

.tech-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tooltip-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 80%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  transition: opacity 0.2s ease;
}

.tech-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Contact Section (bottom) */
.home-contact-section {
  max-width: 2400px;
  margin: 60px auto;
  background: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 30px 150px;
  text-align: center;
  transition: transform 0.2s ease;
}

@media (hover: hover) {
  .home-contact-section:hover {
    transform: scale(1.02);
  }
}

.home-contact-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.home-contact-section p {
  font-size: 1rem;
  color: #555;
}

.contact-details {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: #555;
  cursor: default;
}

.contact-icon {
  font-size: 1.2rem;
  color: #666;
}

/* Underline email on hover */
.email-item:hover .clickable-email {
  text-decoration: underline;
  cursor: pointer;
}

/* Fade for "Copied to clipboard!" */
.clipboard-msg {
  margin-top: 10px;
  color: green;
  font-size: 0.9rem;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.clipboard-msg.show {
  opacity: 1;
  transform: translateY(0);
}

/* Social icons */
.contact-socials {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.contact-social-link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.linkedin-link {
  background-color: #0B65C2;
}
.github-link {
  background-color: #1F2328;
}
.youtube-link {
  background-color: #FF0000;
}

.contact-social-link:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .home-contact-section {
    margin: 40px 20px;
    padding: 20px;
  }
}
