/* Basic styling for the site-wide footer */
.site-footer {
    width: 100%;
    background-color: #ddd;
    padding: 10px 20px;
    text-align: left;
    color: #333;
    font-size: 0.9rem;
  }
  /* 
  .footer-left {
    /* Could add more styling if needed 
  }
  */